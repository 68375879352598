import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import "./SavedSearch.css";
import { useSelector } from "react-redux";
import { agentSharelists, ShareSearch, claimAgentList } from '../../components/Api/ListingApi';
import ToastPopup from '../../components/popup/ToastPopup';
import { ToastContainer, toast } from "react-toastify"


export default function SavedSearchShare(props) {
    const { savedShare, SavedSearchShare, editDetails, reload } = props;
    const userDetails = useSelector(state => state.user.userDetail);
    const [myLeadData, setMyLeadData] = useState([])
    const [otherSpecialData, setOtherSpecialData] = useState([]);
    const [messages, setMessages] = useState("")
    const [success, setSucess] = useState(false);
    const [agentData, setAgentData] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryLead, setSearchQueryLead] = useState('');
    
    const toggleSuccess = () => setSucess(p => !p);
    console.log("editDetails", editDetails)
    const onChangeotherSpecial = (id) => {
        // console.log("idddd", id)
        const checkboxesCopy = [...otherSpecialData];
        const findIdx = checkboxesCopy.indexOf(id);
        if (findIdx > -1) {
            checkboxesCopy.splice(findIdx, 1);
        } else {
            checkboxesCopy.push(id);
        }
        setOtherSpecialData(checkboxesCopy);
    };
    useEffect(() => {
        if (userDetails.role !== "user") {
            myLeadList()
        } else {
            agentList()
        }

    }, [])
    const handleSearchChange1 = (e) => {
        console.log("checkkk", e)
        setSearchQueryLead(e.target.value);
    };
    const myLeadList = async () => {
        let userId = localStorage.getItem('userId')
        let getParams = {
            id: userId,
            search: searchQueryLead
        }

        await agentSharelists(getParams).then((res) => {
            // console.log("response my lead", res)
            setMyLeadData(res.data.data)

        })
    }
    const handleSearchChange = (e) => {
        console.log("checkkk", e)
        setSearchQuery(e.target.value);
    };
    const agentList = async () => {
        let userId = localStorage.getItem('userId')
        let getParams = {
            search: searchQuery
        }
        await claimAgentList(userId, getParams).then((res) => {
            console.log("agenrLIst", res.data.data)
            setAgentData(res.data.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const shareSavedSearch = async () => {
        if (userDetails?.role !== "user") {
            var getParams = {
                user_id: otherSpecialData
            }
        } else {
            var getParams = {
                agent_id: otherSpecialData
            }
        }
        // console.log("editDetails._id", editDetails._id)
        await ShareSearch(editDetails._id, getParams).then((res) => {
            reload()
            toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
            });
            setOtherSpecialData([])

        }).catch((error) => {
            console.error(error)
        })

    }




    // Function to handle search when pressing Enter key
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            agentList();
        }
    };

    // Function to handle search when clicking on search icon
    const handleSearchClick = () => {
        agentList(); // Call the search function
    };

    const handleKeyDown1 = (e) => {
        if (e.key === 'Enter') {
            myLeadList();
        }
    };

    // Function to handle search when clicking on search icon
    const handleSearchClick1 = () => {
        myLeadList(); // Call the search function
    };
    const onChangeotherSpecialradio = (id) => {
        // Check if the id is already in the array
        if (otherSpecialData.includes(id)) {
            // If it is, remove it from the array
            setOtherSpecialData(otherSpecialData.filter(item => item !== id));
        } else {
            // If it's not, add it to the array
            setOtherSpecialData([...otherSpecialData, id]);
        }
    };

    return (
        <div>
            <ToastContainer />
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <Modal size="lg md" show={savedShare} onHide={SavedSearchShare} className="modelparent" style={{ widh: "120% !important" }}>

                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare ">
                        {userDetails.role !== "user" ?
                            <Modal.Title className='form-field-label savedSearch'>Select  client(s) you want share<br />{`"${editDetails.name}"`} saved search with</Modal.Title> :
                            <Modal.Title className='form-field-label savedSearch'>Select agent you want assign<br />{`"${editDetails.name}"`} saved search with</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0" >
                        {userDetails?.role !== "user" ?
                            <div>
                                <div className='position-relative savedSearchinput1 mb-3' >
                                    <input
                                        type="search"
                                        className="form-control"
                                        onChange={(e) => handleSearchChange1(e)}
                                        placeholder="Search name"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "0px" }}
                                        onKeyDown={handleKeyDown1} // Call handleKeyDown when a key is pressed
                                    />

                                    <div className='savedsearchImage position-absolute cursor-pointer'>
                                        <img
                                            src="/images/Icon feather-search.png"
                                            alt="Search"
                                            style={{ height: "18px" }}
                                            onClick={handleSearchClick1} // Call handleSearchClick on click
                                        />
                                    </div>
                                </div>
                                <div className="form-check row specialities" style={{ height: "250px", overflow: "auto" }}>
                                    {myLeadData.map((checkbox) => (
                                        <label className='AgentListSearch' key={checkbox.user_id._id} >
                                            {checkbox.user_id.name}
                                            <input
                                                type="checkbox"
                                                onChange={() => onChangeotherSpecial(checkbox.user_id._id)}
                                                // checked={otherSpecialData.includes(checkbox.id)}
                                                checked={otherSpecialData.includes(checkbox.user_id._id) ? true : false}
                                                className="form-check-input inputStyle languageDetails borderDetails "
                                            />
                                        </label>
                                    ))}
                                    <div className='resulttext'>
                                        {myLeadData.length == 0 && <div className='no-resultSell mt-5'> Result not found</div>}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <Button className="mb-3 mt-3 sharesSearchbut" onClick={() => { shareSavedSearch(); SavedSearchShare() }}>Share</Button>
                                </div>
                            </div> : <div>
                                <div className='position-relative savedSearchinput1 mb-3' >
                                    <input
                                        type="search"
                                        className="form-control"
                                        onChange={(e) => handleSearchChange(e)}
                                        placeholder="Search name"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "0px" }}
                                        onKeyDown={handleKeyDown} // Call handleKeyDown when a key is pressed
                                    />
                                    <div className='savedsearchImage position-absolute cursor-pointer'>
                                        <img
                                            src="/images/Icon feather-search.png"
                                            alt="Search"
                                            style={{ height: "18px" }}
                                            onClick={handleSearchClick} // Call handleSearchClick on click
                                        />
                                    </div>
                                </div>
                                <div className="form-check row specialities" style={{ height: "250px", overflow: "auto" }}>
                                    {agentData.map((radio) => (
                                        radio?.agent_id ? (
                                            <label className='AgentListSearch' key={radio?.agent_id?._id ? radio.agent_id._id : ""} >
                                                {radio?.agent_id?.name ? radio.agent_id.name : ""}
                                                <input
                                                    type="radio"
                                                    name="agentSelection"
                                                    onChange={() => onChangeotherSpecialradio(radio?.agent_id ? radio.agent_id._id : "")}
                                                    checked={(editDetails.shared_agent[0]?.agent_id === radio.agent_id._id) || otherSpecialData.includes(radio?.agent_id?._id ? radio.agent_id._id : "")}
                                                    className="form-check-input inputStyle languageDetails borderDetails"
                                                />
                                            </label>
                                        ) : null
                                    ))}
                                    <div className='resulttext'>
                                        {agentData.length == 0 && <div className='no-resultSell mt-5'> Result not found</div>}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <Button className="mb-3 mt-3 sharesSearchbut" onClick={() => { shareSavedSearch(); SavedSearchShare() }}>Assign</Button>
                                </div>
                            </div>}
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}
